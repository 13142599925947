<template>
  <v-card style="min-height: 100vh" class="bg-light" elevation="0">
    <v-app-bar outlined short color="#ffffff" style="z-index:999;" elevation="0.5">
      <v-icon @click="back()">mdi-arrow-left</v-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title
        align-self="center"
        class="text-button font-weight-6 grey--text text--darken-2 p-0 m-0"
      >
        {{ isLoggedIn ? 'Voucher Wallet' : 'Unclaimed Vouchers' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-slot:extension>
        <v-text-field
          v-model.trim="keyword"
          color="grey lighten-1"
          class="search-voucher-code text-caption mb-4"
          placeholder="Enter voucher code here"
          rounded
          dense
          filled
          hide-details
          autocomplete="off"
        ></v-text-field>
      </template>
    </v-app-bar>
    <v-sheet
      height="calc(100vh - 104px)"
      color="#f3f3f3"
      :class="`${ isLoggedIn ? '' : 'pb-56' } overflow-y-auto p-0`"
    >
      <v-card flat class="m-3" v-if="vouchers.length === 0">
        <v-list color="#ffffff">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 12px !important"
                class="text-caption text-center font-weight-bold mt-2"
              >
                No voucher found
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card
        flat
        v-for="voucher in filteredVoucher()"
        :key="voucher.id"
        class="m-3 p-0"
      >
        <v-row class="mx-0">
          <v-col
            class="voucher-owner align-content-center p-2"
            :class="{ 'voucher-product': isProductVoucher(voucher) }"
            cols="3"
          >
            <v-row class="mx-0">
              <v-col class="p-0">
                <v-img
                  :name="`merchant-logo-${voucher.id}`"
                  :src="`${cdnUrl}/${voucher.merchant.logo}`"
                ></v-img>
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-col class="p-0">
                <div class="restaurant-name text-center font-weight-6">{{ voucher.merchant.name }}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="align-content-center pl-3 p-1" cols="7">
            <div
              class="
                text-body-2 text-wrap text-uppercase
                primary--text
                font-weight-bold
              "
            >
              {{ voucher.code }}
              <v-chip
                small
                class="text-caption text-capitalize mr-1 limited-offer"
                style="margin-top:-2px;"
                v-if="voucher.visibility"
                >Private</v-chip
              >
              <v-chip
                small
                class="text-caption text-lowercase mr-1 mb-1 limited-quantity"
                style="margin-top:-2px;"
                v-if="voucher.quantity > 1"
                >x{{ voucher.quantity }}</v-chip
              >
            </div>
            <div class="d-flex flex-column">
              <small
                class="voucher-criteria font-weight-6 green--text text--darken-1 mt-1"
              >
                {{
                  voucher.type === 'percentage'
                    ? voucher.amount + '%'
                    : $n(voucher.amount, 'currency', 'en-MY')
                }}
                off
              </small>
              <small
                class="voucher-criteria font-weight-5 green--text text--darken-1"
              >
                Min. purchase
                <span class="font-weight-6">{{
                  $n(voucher.minPurchase, 'currency', 'en-MY').split('.')[0]
                }}</span>
              </small>
              <small
                class="voucher-criteria font-weight-5 mt-2"
                v-if="isProductVoucher(voucher)"
              >
                * For <span class="font-weight-bold text-wrap">
                <v-avatar size="16">
                  <v-img
                    :src="getProductPhoto(voucher)"
                    v-if="isProductVoucher(voucher)"
                  ></v-img>
                </v-avatar>
                {{ voucher.item.name }}</span> only
              </small>
            </div>
            <div class="voucher-service text-wrap mt-1">
              <v-chip
                small
                class="text-caption mr-1 mt-1 limited-offer"
                v-if="voucher.delivery"
                >Delivery</v-chip
              >
              <v-chip
                small
                class="text-caption mr-1 mt-1 limited-offer"
                v-if="voucher.pickup"
                >Pickup</v-chip
              >
              <v-chip
                small
                class="text-caption mr-1 mt-1 limited-offer"
                v-if="voucher.dinein"
                >Dine In</v-chip
              >
            </div>
            <small class="voucher-criteria" v-if="canUse(voucher.startAt)">
              Valid until
              <b>{{ formatDate(voucher.expiration) }}</b></small
            >
            <small class="voucher-criteria" v-else>
              <v-icon x-small dense style="margin-top:-3px;">mdi-timer-sand</v-icon> Use from: 
              <b>{{ formatDate(voucher.startAt) }}</b></small
            >
          </v-col>
          <v-col
            class="p-0 pr-2"
            :class="{ 'align-content-center': !voucher.fullyRedeemed }"
            cols="2"
          >
            <div class="text-center">
              <div
                class="text-overline font-weight-bold"
                v-if="voucher.fullyRedeemed"
              >
                <div class="stamp p-2">
                  Fully Redeemed
                </div>
              </div>
              <div
                style="cursor: pointer"
                class="font-weight-bold"
                v-else-if="isLoggedIn"
              >
                <v-btn
                  x-small
                  outlined
                  ripple
                  color="primary"
                  class="btn-cta-use px-3 py-1"
                  @click.stop="apply(voucher)"
                  v-if="canUse(voucher.startAt)"
                  >Use</v-btn
                >
                <v-btn
                  x-small
                  outlined
                  ripple
                  color="disabled"
                  class="btn-cta-use px-3 py-3"
                  v-else
                >
                  Use<br/>Later
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-bottom-navigation
        fixed
        background-color="primary"
        v-if="!isLoggedIn"
      >
        <v-btn
          block
          class="text-body-1 font-weight-bold align-self-center text-uppercase"
          @click="$router.push({name: 'login', query: { redirectFullPath: 'vouchers' }}).catch(() => {})"
        >
          <v-icon v-if="isLoading">mdi-loading</v-icon>
          <template v-else>
            <div class="white--text">Claim Now</div>
          </template>
        </v-btn></v-bottom-navigation
      >
    </v-sheet>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showNotify" v-if="claimedVoucher" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar
          flat
          dense
          color="primary"
        >
          <v-btn
            icon
            dark
          >
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-overline font-weight-6 white--text justify-center">Voucher claimed!</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-1"
            icon
            dark
            x-small
            @click="showNotify = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-overline font-weight-6 primary--text justify-center"></v-card-title>
        <v-card-text class="text-caption pb-3">
          <div>
            <v-avatar size="100%" class="rounded-xl">
              <v-img
                class="elevation-6 mb-5"
                :src="getProductPhoto(claimedVoucher)"
                v-if="isProductVoucher(claimedVoucher)"
              ></v-img>
            </v-avatar>
          </div>
          You have successfully claimed the <span class="text-body-2 font-weight-bold" v-if="isProductVoucher(claimedVoucher)">{{ claimedVoucher.item.name }} </span>voucher (<small class="text-body-2 font-weight-bold blue--text">{{ claimedVoucher.code }}</small>)!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="font-weight-bold m-2 p-3"
            small
            @click="apply(claimedVoucher)"
            v-if="canUse(claimedVoucher.startAt)"
          >
            Use Now
          </v-btn>
          <v-btn
            color="disabled"
            class="font-weight-bold m-2 p-3"
            small
            outlined
            ripple
            v-else
          >
            Use Later
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUnclaimVoucher" v-if="claimedVoucher" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar
          flat
          dense
          color="primary"
        >
          <v-btn
            icon
            dark
          >
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-overline font-weight-6 white--text justify-center">You got a Voucher!</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-1"
            icon
            dark
            x-small
            @click="showUnclaimVoucher = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-body-1 text-center font-weight-6 p-1 pr-3">
          <div class="voucher py-3 m-1">
            <v-row class="p-0 m-0">
              <v-col cols="4" style="border-right: 1px solid rgba(0, 0, 0, 0.3);">
                <div
                  class="m-auto"
                >
                  <div class="avatar bg-light">
                    <v-img
                      :name="`merchant-logo`"
                      :src="`${cdnUrl}/${claimedVoucher.merchant.logo}`"
                    ></v-img>
                  </div>
                  <small
                    class="font-weight-6 primary--text"
                    style="font-size: 9px !important"
                    v-html="claimedVoucher.merchant.name"
                  >
                  </small>
                </div>
              </v-col>
              <v-col cols="8" class="m-auto">
                <div class="mb-2">
                  Get <small class="text-h5 font-weight-bold blue--text mx-1">{{ claimedVoucher.type == 'percentage' ? `${claimedVoucher.amount}%` : $n(claimedVoucher.amount, 'currency', 'en-MY') }}</small> off
                </div>
                <div class="text-caption font-weight-6 mb-2" v-if="isProductVoucher(claimedVoucher)">
                  for
                  <v-avatar size="26" class="mx-1">
                    <v-img
                      :src="getProductPhoto(claimedVoucher)"
                      v-if="isProductVoucher(claimedVoucher)"
                    ></v-img>
                  </v-avatar>
                  <span class="text-body-2 font-weight-bold">{{ claimedVoucher.item.name }}</span>
                </div>
                <small class="text-caption">Use voucher code</small>
                <br/>
                <small class="text-h5 font-weight-bold primary--text">{{ claimedVoucher.code }}</small>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="font-weight-bold m-1 p-3"
            small
            @click="claim(claimedVoucher)"
          >
            Claim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="showService">
      <v-card min-height="30vh" max-height="60vh" class="overflow-y-auto p-0">
        <v-card-title class="text-button font-weight-6 justify-center">
          <v-icon color="primary" class="mr-2">mdi-food</v-icon>
          Services
        </v-card-title>
        <v-card height="8" color="#f3f3f3"></v-card>
        <v-card flat color="#f3f3f3" class="pb-2 px-0">
          <v-list color="#ffffff">
            <v-list-item
              v-for="service in services"
              :key="service.value"
              @click="selectService(service.label, service.value)"
            >
              <v-list-item-avatar class="my-0">
                <v-icon color="primary">{{
                  serviceIcons[service.value]
                }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-caption font-weight-6">{{
                  service.label
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      keyword: '',
      isLoading: false,
      showService: false,
      showNotify: false,
      showUnclaimVoucher: false,
      services: [],
      claimedVoucher: null,
    };
  },
  computed: {
    ...mapState('client', {
      vouchers: (state) => state.vouchers,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapState('order', {
      orders: (state) => state.list,
    }),
    ...mapGetters('auth', {
      isLoggedIn: ['isLoggedIn'],
    }),
    ...mapGetters('cart', {
      serviceIcons: ['getServiceIcons'],
    }),
  },
  methods: {
    ...mapActions('client', ['getVoucher', 'getVouchers']),
    ...mapMutations('cart', ['SET_SERVICE', 'SET_VOUCHER']),
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY');
    },
    canUse(startAt) {
      return !startAt || !dayjs().isBefore(dayjs(startAt), 'day');
    },
    isProductVoucher(voucher) {
      return voucher.type === 'product';
    },
    getProductPhoto(voucher) {
      return voucher?.item?.photo_s3
        ? `${this.cdnUrl}/${voucher?.item?.photo_s3}`
        : `${this.legacyCdnUrl}/upload/${voucher?.item?.photo}`;
    },
    back() {
      if (this.$route.query.id || this.prevRoute?.name == 'login') {
        this.$router.push({ name: 'home' }).catch(() => {});
      } else {
        this.$router.go(-1);
      }
    },
    filteredVoucher() {
      return this.vouchers?.filter((v) => {
        return (
          !this.keyword ||
          v.code.toLowerCase().includes(this.keyword.toLowerCase()) ||
          v.merchant.name.toLowerCase().includes(this.keyword.toLowerCase())
        );
      });
    },
    apply(voucher) {
      if (this.$route.query.id && !voucher) {
        voucher = this.claimedVoucher;
      }

      this.$gtag.event('apply-voucher', {
        event_category: 'engagement',
        event_label: `Apply Voucher (${voucher?.merchant.name})`,
        event_value: voucher,
      });

      // if (voucher?.merchant.hostname === location.hostname) {
        this.SET_VOUCHER({
          id: voucher.id,
          code: voucher.code,
          type: voucher.type,
          amount: voucher.amount,
          min_purchase: voucher.minPurchase,
          item: voucher.item,
        });

        this.showServiceSheet(voucher?.delivery, voucher?.pickup, voucher?.dinein);
      // } else {
      //   const iframe = document.getElementById('sso').contentWindow;
      //   iframe.postMessage(
      //     {
      //       action: 'apply-voucher',
      //       value: voucher,
      //     },
      //     '*'
      //   );

      //   window.location.href = `https://${voucher?.merchant.hostname}`;
      // }
    },
    claim(voucher) {
      this.$router.push({ name: 'login', query: { redirectFullPath: `/vouchers?id=${voucher.id}` } }).catch(() => {});
    },
    showServiceSheet(delivery, pickup, dinein) {
      this.services = [];

      if (delivery) {
        this.services.push({
          label: 'Delivery',
          value: 'delivery',
        });
      }

      if (pickup) {
        this.services.push({
          label: 'Pick Up',
          value: 'pickup',
        });
      }

      if (dinein) {
        this.services.push({
          label: 'Dine In',
          value: 'dinein',
        });
      }

      this.showService = true;
    },
    selectService(label, value) {
      this.$gtag.event('select-service', {
        event_category: 'navigation',
        event_label: label,
        event_value: value,
      });

      this.SET_SERVICE(value);
      this.showService = false;

      if (value == 'dinein') {
        this.$router.push({ name: 'scan' }).catch(() => {});
      } else {
        this.$router.push({ name: 'menu' }).catch(() => {});
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.prevRoute = from);
  },
  async mounted() {
    if (this.$route.query.login) {
      this.$router.push({ name: 'login' }).catch(() => {});
    }

    this.isLoading = true;
    await this.getVouchers({
      merchantId: this.profile.merchant_id,
    });
    this.isLoading = false;

    if (this.$route.query.id) {
      this.isLoading = true;
      this.claimedVoucher = await this.getVoucher({
        id: this.$route.query.id,
        params: {
          merchantId: this.profile.merchant_id,
        },
      });
      this.isLoading = false;

      if (this.claimedVoucher) {
        if (this.isLoggedIn) {
          this.showNotify = true;
        } else {
          this.showUnclaimVoucher = true;
        }
      }
    }
  },
};
</script>
<style scoped>
.v-list-item__action-text {
  color: #ff6347 !important;
}

.pb-56 {
  padding-bottom: 56px !important;
}

.limited-offer {
  font-size: 8px !important;
  font-weight: 600;
  height: 18px;
  padding: 0 8px;
  border-radius: 4px;
  color: #039be5 !important;
  background-color: rgba(3, 155, 229, 0.1) !important;
}

.limited-quantity {
  font-size: 10px !important;
  font-weight: bold;
  height: 20px;
  border-radius: 4px;
  color: #ff6347 !important;
  background-color: rgba(255, 99, 71, 0.1) !important;
}

.stamp {
	position: absolute;
	top: 50%;
	right: 8px;
  transform: translateY(-50%) rotate(-15deg);
	color: #ff6347;
  line-height: 0.75rem;
	font-size: 0.4rem;
	font-weight: 700;
	border: 0.25rem solid #ff6347;
	text-transform: uppercase;
	border-radius: 1rem;
	mask-image: url('../../assets/images/grunge.webp');
	-webkit-mask-image: url('../../assets/images/grunge.webp');
  mix-blend-mode: multiply;
}

.voucher {
  border: 5px dotted #bbb;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

.voucher-owner {
  min-height: 110px;
  font-size: 10px;
  border: 0.55px solid var(--v-primary);
}

.voucher-owner.voucher-product {
  height: 150px;
}

.voucher-owner .restaurant-name {
  font-size: 8px;
}

.voucher-criteria {
  font-size: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.voucher-service {
  margin-left: -3px;
}

.btn-cta-use {
  width: 45px;
  font-size: 7px;
}
</style>